
//
// VARIABLES
//

// Colors
$blue: #0f7699;

// Grays
$black: #000;
$darkerGray: #222;
$darkGray: #333;
$gray: #777;
$lightGray: #eee;
$white: #fff;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&display=swap');

// Font stacks
$helvetica: Helvetica, Arial, sans-serif;
$helveticaNeue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$georgia: Georgia, serif;
$mainfonts: -apple-system,"Roboto",Helvetica,Arial,sans-serif;

// image sizes
$people_image_size: 175px;

// Mobile breakpoints
@mixin mobile {
  @media screen and (max-width: 800px) {
    @content;
  }
}